<template>
  <auth-layout layoutClass="reset-password-page">
    <router-view />
  </auth-layout>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout.vue";

export default {
  name: "ResetPasswordPage",
  components: { AuthLayout },
};
</script>

<style lang="scss">
</style>